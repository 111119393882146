<template>
  <div>
    <template v-if="errors.code">
      <a-result :status="String(errors.code)" :title="errors.code" :sub-title="errors.message">
        <template #extra>
          <a-button type="primary" @click="$router.back()">
            Back to previous page
          </a-button>
        </template>
      </a-result>
    </template>
    <template v-else>
      <div
        class="mb-4 position-sticky bg-white border-bottom"
        style="top: 78px; z-index: 5; margin: -20px -35px 0; padding: 15px 30px"
      >
        <a-breadcrumb
          separator=">"
        >
          <a-breadcrumb-item>
            <router-link :to="{ path: '/product', query: { business_id: businessId, workspace_id: $route.query.workspace_id } }">
              {{ $t('product.title') }}
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <router-link :to="{ path: '/product', query: { business_id: businessId, workspace_id: $route.query.workspace_id } }">
              {{ $t('product.listProduct') }}
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            {{ business.business_title }}
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            {{ $t('product.addProduct') }}
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>

      <CreateProductForm
        v-if="showForm"
        ref="form"
        :product-id-draft="productIdDraft"
        :business="business"
        :product="product"
        :draft-image="draftImage"
        :permission="permission"
        @updateDraftId="updateDraftId"
      />
    </template>

    <a-modal
      :visible="!!draftId"
      :title="false"
      :footer="false"
      :closable="false"
      class="draft-confirm"
    >
      <div class="draft-confirm__title text-center">
        Anda memiliki draf produk
      </div>
      <div class="text-center">
        Untuk buat produk baru Anda dapat melanjutkan dengan draf atau buat baru
      </div>
      <div class="draft-confirm__options">
        <div>
          <input
            id="draft-confirm__continue-draft"
            v-model="continueDraft"
            name="continue-draft"
            type="radio"
            :value="true"
          />
          <label for="draft-confirm__continue-draft">
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2.23814 2.71298C4.77782 -0.461619 22.5556 -0.46162 25.0953 2.71298C25.8439 3.64874 26.3718 6.23945 26.6791 9.50948C25.4449 9.04686 24.1237 8.76196 22.747 8.68593C22.6239 8.03933 22.2024 7.46298 21.4824 7.29771C19.7456 6.89903 7.58784 6.89903 5.85103 7.29771C4.11421 7.69639 4.11421 10.4872 5.85103 10.8859C6.76386 11.0954 10.5553 11.1948 14.1995 11.1841C13.5357 11.6638 12.9178 12.203 12.3536 12.7941C9.35585 12.8161 6.60957 12.9142 5.85103 13.0883C4.11421 13.487 4.11421 16.2778 5.85103 16.6765C6.37205 16.7961 7.83091 16.8798 9.66491 16.9276C9.44265 17.4677 9.25482 18.0256 9.10416 18.5985C7.37783 18.6358 5.9057 18.7293 5.45301 18.8789C4.24688 19.2776 4.24688 22.0684 5.45301 22.4671C5.86874 22.6045 7.14424 22.6946 8.68682 22.7373C8.94252 27.4208 11.6153 31.4608 15.4793 33.6314C9.85432 33.8435 3.65956 33.0612 2.23814 31.2844C-0.301546 28.1098 -0.301547 5.88759 2.23814 2.71298Z" fill="currentColor" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M33.6703 21.9967C33.6703 28.44 28.447 33.6633 22.0037 33.6633C15.5603 33.6633 10.337 28.44 10.337 21.9967C10.337 15.5533 15.5603 10.33 22.0037 10.33C28.447 10.33 33.6703 15.5533 33.6703 21.9967ZM24.9887 16.5665C26.0794 16.4648 27.73 18.1154 27.6283 19.2061C28.2127 18.5575 28.6232 18.0597 28.7413 17.8311C29.3068 16.7373 27.4576 14.888 26.3637 15.4535C26.1351 15.5716 25.6373 15.9822 24.9887 16.5665ZM20.1169 26.5226C21.3024 25.7974 26.6192 20.4582 27.0002 19.5722C27.3989 18.6452 25.5496 16.7959 24.6226 17.1946C23.7366 17.5756 18.3974 22.8925 17.6722 24.0779C18.4095 24.3259 19.869 25.7853 20.1169 26.5226ZM14.9943 29.2005C14.7169 28.9231 16.0813 25.3396 16.6998 24.7211C17.3182 24.1027 20.0922 26.8766 19.4737 27.4951C18.8552 28.1135 15.2717 29.4779 14.9943 29.2005Z" fill="currentColor" />
            </svg>
            <span>Lanjutkan Draf</span>
          </label>
        </div>
        <div>
          <input
            id="draft-confirm__delete-draft"
            v-model="continueDraft"
            name="continue-draft"
            type="radio"
            :value="false"
          />

          <label for="draft-confirm__delete-draft">
            <svg
              width="35"
              height="34"
              viewBox="0 0 35 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.7064 0.332031C20.9392 0.332031 29.4048 4.4987 29.4048 5.88759C29.4048 7.27648 19.5283 11.4431 16.7064 11.4431C13.8845 11.4431 4.00798 7.27648 4.00798 5.88759C4.00798 4.4987 12.4736 0.332031 16.7064 0.332031Z" fill="currentColor" />
              <path d="M31.4706 9.85584C29.9921 9.85584 21.7358 13.5961 19.6428 15.6449C18.9557 16.3174 18.5604 18.1322 18.3917 20.3181C20.1103 17.833 22.9801 16.205 26.2302 16.205C28.656 16.205 30.87 17.112 32.5516 18.6053C32.6873 14.3015 32.3269 9.85584 31.4706 9.85584Z" fill="currentColor" />
              <path d="M13.77 15.6449C11.677 13.5961 3.42071 9.85584 1.94223 9.85584C0.46375 9.85584 0.463761 23.107 1.94223 26.0016C3.4207 28.8961 12.2915 33.2379 13.77 31.7906C15.2485 30.3433 15.863 17.6937 13.77 15.6449Z" fill="currentColor" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M26.2302 33.6654C30.6134 33.6654 34.1667 30.1121 34.1667 25.7289C34.1667 21.3456 30.6134 17.7923 26.2302 17.7923C21.847 17.7923 18.2937 21.3456 18.2937 25.7289C18.2937 30.1121 21.847 33.6654 26.2302 33.6654ZM26.2302 21.1654C26.5589 21.1654 26.8254 21.4319 26.8254 21.7606V25.1336H30.1985C30.5272 25.1336 30.7937 25.4001 30.7937 25.7289C30.7937 26.0576 30.5272 26.3241 30.1985 26.3241H26.8254V29.6971C26.8254 30.0259 26.5589 30.2924 26.2302 30.2924C25.9015 30.2924 25.635 30.0259 25.635 29.6971V26.3241H22.2619C21.9332 26.3241 21.6667 26.0576 21.6667 25.7289C21.6667 25.4001 21.9332 25.1336 22.2619 25.1336H25.635V21.7606C25.635 21.4319 25.9015 21.1654 26.2302 21.1654Z" fill="currentColor" />
            </svg>
            <span>Hapus Draf dan Buat Baru</span>
          </label>
        </div>
      </div>

      <div class="draft-confirm__footer">
        <button class="ant-btn ant-btn-lg" @click="onDraftConfirmCancel">
          Batal
        </button>
        <button class="ant-btn ant-btn-lg ant-btn-primary" :disabled="continueDraft === null" @click="onDraftConfirmContinue">
          Selanjutnya
        </button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import CreateProductForm from '@/components/Store/Shopee/ProductForm/index.vue'
import { getChannelDraft, channelImageDraft, deleteChannelDraft } from '@/api/channels/index'
import { getProductDraft, deleteProduct } from '@/api/product'

export default defineComponent({
  name: 'CreateProductPage',
  components: { CreateProductForm },
  beforeRouteLeave(to, from, next) {
    return this.$refs.form ? this.$refs.form.beforeRouteLeave(to, from, next) : next()
  },
  data() {
    return {
      product: null,
      fetchingDraft: false,
      fetchingProduct: false,
      draftId: null,
      /** @type {null | boolean} */
      continueDraft: null,
      errors: {
        message: null,
        code: null,
      },
      productIdDraft: '',
      draftImage: [],
      draftPayload: {
        shop_id: "",
        is_cod_open: false,
        detail: {
            title: '',
            long_description: '',
            category: {
                id: '',
            },
            images: [],
        },
        catalogs: [],
        product_unit: {},
      },
    }
  },
  computed: {
    productId() {
      return String(this.$route.query.id || '')
    },
    
    /**
     * @returns {string}
     */
    businessId() {
      return String(this.$route.query['business_id'] || '')
    },

    /**
     * @returns {import('@/types/utils').Business}
     */
    business() {
      return this.$store.state.user.businessList.find(business => business.business_id === this.businessId) || {}
    },
    showForm() {
      return !!(!this.fetchingDraft && !this.fetchingProduct && !this.draftId)
    },
    permission() {
      return this.$store.getters['user/can']('product-list')
    },
    httpHeader() {
      return {
        'Business-Id': this.businessId,
      }
    },
  },
  watch: {
    productId() {
      this.fetchProductDetail()
    },
    // permission(newValue) {
    //   if(!newValue.length || newValue[1] !== 'WRITE') {
    //     this.$router.push({path: '/error/403', query: {...this.$route.query}})
    //   }
    // },
  },
  mounted() {
    this.fetchProductDetail()
  },
  methods: {
    updateDraftId(param) {
      this.productIdDraft = param
    },
    async fetchProductDetail() {
      if (!this.productId) return

      this.errors.message = null
      this.fetchingProduct = true
      try {
        const { data: { data } } = await getProductDetail({ 
          id: this.productId,
          params: { business_id: this.businessId },
        })

        this.product = data
      } catch (err) {
        const code = err.response?.status
        this.errors.code = code
        this.errors.message = code === 404 ? 'Produk tidak ditemukan' : (err.response?.data?.message || 'Terjadi kesalahan')
      }
      this.fetchingProduct = false
    },
    async getDraftProduct() {
      if (this.productId) return

      this.fetchingDraft = true

      try {
        const { data: response } = await getProductDraft(this.businessId)
        this.draftId = response?.data?.id || response?.data?.product_id
      } catch (err) {
        // do nothing
      }

      this.fetchingDraft = false
    },
    onDraftConfirmCancel() {
      this.$router.back();
    },
    async fetchDraftImage() {
      const response = await channelImageDraft({
        business_id: this.businessId,
        channel_code: 'shopee_id',
        channel_id: this.$route.params.id,
        user_id: this.$store.state.user.id,
        product_id: this.productIdDraft,
        params: undefined,
      })
      if (response.data.message === "Success") {
        this.draftImage = response.data.data.images
      }
    },
    async fetchDraft() {
      const response = await getChannelDraft({
        business_id: this.businessId,
        channel_code: 'tiktok',
        channel_id: this.$route.params.id,
        user_id: this.$store.state.user.id,
      })
      if (response.data.message === "Success") {
        this.productIdDraft = response.data.data.product_id
        if (response.data.data.draft) {
          this.draftPayload = response.data.data.draft
        }
        this.fetchDraftImage()
      }
    },
    async onDraftConfirmContinue() {
      if (this.continueDraft === null) return

      if (this.continueDraft) {
        this.$router.push({ query: { ...this.$route.query, id: this.draftId }})
        this.continueDraft = null
        this.draftId = null
        return 
      }
      await deleteProduct(this.draftId)
      await deleteChannelDraft({
        channel_code: this.$route.query.channel_code,
        channel_id: this.$route.params.id, 
        user_id: this.$store.state.user.id, 
        product_id: this.productIdDraft, 
        business_id: this.businessId,
      })
      this.continueDraft = null
      this.draftId = null
      this.productIdDraft = ''
    },
  },
})
</script>

<style lang="scss">
.draft-confirm {

  .ant-modal-body {
    color: #999;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 500;
    color: #141414;
    margin-bottom: 1rem;
  }

  &__options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 1rem;
    margin: 1.5rem 0;
    
    > * {
      width: 100%;
      height: 100%;
    }

    svg {
      height: 25px;
      margin-right: 1rem;
    }

    label {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      width: 100%;
      border: 1px solid #efefef;
      padding: 1rem;
      cursor: pointer;
    }

    input {
      display: none;

      &:checked {
        ~ label {
          color: var(--kit-color-primary);
          border-color: var(--kit-color-primary);
        }
      }
    }
  }

  &__footer {
    display: flex;
    gap: 1rem;

    button {
      width: 100%;
    }
    
  }
}
</style>
